import React, { useReducer, useEffect } from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import Head from 'next/head';
import { signIn, getCsrfToken, getSession } from 'next-auth/react';
import { object, string, reach } from 'yup'; // TODO Replace this with zod CN20230523

import Icon from '@lushdigital/icons';

import Container from '@lushdigital/ui/container';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import CTA from '@lushdigital/ui/cta';
import InputField from '@lushdigital/ui/inputField';
import Button from '@lushdigital/ui/button';

import { ctaStyle, iconStyle, rowProps, inputProps } from '@views/login/login';

import { autoDismissDefaultTime } from '@tills/config/alertConfig';
import Alert from '@/components/Alert';

import GROUPS from '@/manager-next/config/groups.json';
import GROUPS_PROD from '@/manager-next/config/groups_production.json';

// COMPONENTS

const ENV = process.env.NEXT_PUBLIC_BUILD_ENV || process.env.NODE_ENV;

// Validation Schema
const schema = object().shape({
  email: string().email().required(),
  password: string().required()
});

// const routes = appEnvironmentRoutes(ENV);

type LoginState = {
  showAlert: boolean;
  email: {
    value: string | undefined;
    error: boolean;
  };
  password: {
    value: string | undefined;
    error: boolean;
  };
  showErrors: boolean;
  loading: boolean;
};

const initialState: LoginState = {
  showAlert: false,
  email: {
    value: '',
    error: false
  },
  password: {
    value: '',
    error: false
  },
  showErrors: false,
  loading: false
};

const reducer = (
  state: LoginState,
  action: { type: string; payload: string | { value: string; error: boolean } | boolean }
) => {
  switch (action?.type) {
    case 'email': {
      if (typeof action.payload !== 'object') return state;
      return {
        ...state,
        email: {
          ...state.email,
          value: action?.payload.value,
          error: action?.payload.error
        }
      };
    }
    case 'password': {
      if (typeof action.payload !== 'object') return state;
      const { password } = initialState;
      return {
        ...state,
        password: {
          ...password,
          value: action?.payload?.value,
          error: action?.payload?.error
        }
      };
    }
    case 'showErrors': {
      if (typeof action.payload !== 'boolean') return state;
      return {
        ...state,
        showErrors: action?.payload
      };
    }
    case 'loading': {
      if (typeof action.payload !== 'boolean') return state;
      return {
        ...state,
        loading: action?.payload
      };
    }
    case 'showAlert': {
      if (typeof action.payload !== 'boolean') return state;
      return {
        ...state,
        showAlert: action?.payload
      };
    }
    default:
      return state;
  }
};

export async function getServerSideProps(context) {
  return {
    props: {
      csrfToken: await getCsrfToken(context)
    }
  };
}

const Login = ({ csrfToken }: { csrfToken: string | null }): JSX.Element | null => {
  /**
   * Language translations
   */
  const { t } = useTranslation('tills');

  /**
   * Router
   */
  const router = useRouter();

  /**
   * Local state
   */
  // const [currentDate, setCurrentDate] = useState(null);

  /**
   * Zustand store
   */

  /**
   * API Mutations
   */

  const [state, dispatch] = useReducer(reducer, initialState);
  const { email, password, showErrors, showAlert, loading } = state;

  useEffect(() => {
    if (router.query.error) {
      dispatch({ type: 'showErrors', payload: router.query.error as string });
      dispatch({ type: 'email', payload: router.query.email as string });
    }
  }, [router]);

  const validateUserRoles = async () => {
    const session = await getSession();
    if (!session || !session?.user) return false;
    const groups = ENV === 'production' ? GROUPS_PROD : GROUPS;
    return session.user.roles.some((role) => groups.TILLS.ALL.includes(role));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: 'loading', payload: true });
    const user = { email: email.value, password: password.value };
    try {
      const isValid = await schema.isValid(user);
      if (isValid) {
        dispatch({ type: 'showErrors', payload: false });
        try {
          const res = await signIn('credentials', {
            redirect: false,
            email: email.value,
            password: password.value,
            callbackUrl: '/tills'
          });

          const hasValidRole = await validateUserRoles();

          if (res?.error || hasValidRole === false) {
            dispatch({ type: 'showAlert', payload: true });
            dispatch({ type: 'loading', payload: false });
            return;
          }

          if (res?.url) router.push(res.url);
        } catch (error) {
          dispatch({ type: 'showAlert', payload: true });
          dispatch({ type: 'loading', payload: false });
        }
      } else {
        dispatch({ type: 'showErrors', payload: true });
      }
    } catch (error) {
      dispatch({ type: 'showAlert', payload: true });
    }
  };

  const handleChange = (key, value) => {
    dispatch({ type: 'showErrors', payload: false });
    dispatch({
      type: key,
      payload: {
        error: state[key].error,
        value
      }
    });
  };

  const handleBlur = (key, value) => {
    reach(schema, key)
      .isValid(value)
      .then((valid) => {
        dispatch({
          type: key,
          payload: {
            value: state[key].value,
            error: !valid
          }
        });
        dispatch({ type: 'showErrors', payload: !valid });
      });
  };

  const handleClearAlert = () => {
    dispatch({ type: 'showAlert', payload: false });
  };

  const handleClearAlertTimeout = () => {
    setTimeout(() => {
      dispatch({ type: 'showAlert', payload: false });
    }, autoDismissDefaultTime);
  };

  // const forgottenPassword = `${ routes.ACCOUNT }/forgot-password`.replace(':language', 'en');
  const forgottenPassword = `https://labs.lush.com/${router.locale || 'en'}/my-account/forgot-password`;

  return (
    <>
      <Head>
        <title>{`${t('SIGN_IN')} | ${t('SHOP_MANAGER')}`}</title>
      </Head>
      <div className="flex min-h-screen w-screen items-center justify-center bg-black">
        <form
          className="flex min-h-screen	w-full flex-col	items-center justify-center p-10"
          onSubmit={(e) => handleSubmit(e)}
        >
          <input name="csrfToken" type="hidden" defaultValue={csrfToken || undefined} />
          {showAlert ? (
            <Alert
              theme="error"
              message="LOGIN_FAILED"
              dismissable={() => handleClearAlert()}
              autoDismiss={() => handleClearAlertTimeout()}
            />
          ) : null}

          <div className="flex w-full flex-1 flex-col items-center justify-center md:flex-none">
            <h2 className="mb-10 items-center text-5xl font-semibold text-white" data-testid="sign-in-title">
              {t('SIGN_IN')}
            </h2>
            <Container maxWidth="375px">
              <Row flexDirection="column">
                <Column flexBasis="auto">
                  <InputField
                    type="email"
                    hideLabel
                    name="email"
                    label={t('EMAIL_ADDRESS')}
                    placeholder={t('EMAIL_ADDRESS')}
                    onChange={(e, value) => handleChange('email', value)}
                    onBlur={(e, value) => handleBlur('email', value)}
                    value={email.value}
                    forceTouch
                    showError={false}
                    error={t('EMAIL_INVALID')}
                    prefix={<Icon styles={iconStyle} icon="profile" fill="#fff" />}
                    inputProps={{
                      ...inputProps,
                      'data-testid': 'email-input'
                    }}
                    rowProps={rowProps}
                  />
                </Column>
                <Column flexBasis="auto">
                  <InputField
                    type="password"
                    hideLabel
                    name="password"
                    label={t('PASSWORD')}
                    placeholder={t('PASSWORD')}
                    onChange={(e, value) => handleChange('password', value)}
                    onBlur={(e, value) => handleBlur('password', value)}
                    value={password.value}
                    forceTouch
                    showError={password.error && showErrors}
                    error={t('PASSWORD_REQUIRED')}
                    prefix={<Icon styles={iconStyle} icon="lock" fill="#fff" />}
                    inputProps={{
                      ...inputProps,
                      'data-testid': 'password-input',
                      autoComplete: 'off'
                    }}
                    rowProps={rowProps}
                  />
                </Column>
                <Column flexBasis="auto">
                  <CTA
                    loading={loading}
                    fullWidth
                    type="submit"
                    style={ctaStyle}
                    disabled={showErrors}
                    data-testid="sign-in-button"
                  >
                    {t('SIGN_IN')}
                  </CTA>
                </Column>
              </Row>
            </Container>
          </div>
          <Container maxWidth="375px">
            <Row flexDirection="column">
              <Column flexBasis="auto">
                <Button as="a" fullWidth href={forgottenPassword} data-testid="forgotten-password">
                  {t('FORGOTTEN_PASSPORT')}
                </Button>
              </Column>
            </Row>
          </Container>
        </form>
      </div>
    </>
  );
};

Login.hideNav = true;

export default Login;
