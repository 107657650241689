import React, { useEffect } from 'react';
import UseTranslation from 'next-translate/useTranslation';
import Icon from '@lushdigital/icons';
import clsx from 'clsx';

type AlertProps = {
  theme: 'error' | 'warning' | 'info' | 'success';
  message: string;
  dismissable: (() => void) | undefined;
  autoDismiss: (() => void) | undefined;
};

const Alert = ({ theme, message, dismissable, autoDismiss }: AlertProps) => {
  const { t } = UseTranslation('tills');
  const autoDismissStyles =
    'after:animate-loadSwipe after:absolute after:left-0 after:top-0 after:block after:h-1 after:w-full after:bg-white after:opacity-30';

  useEffect(() => {
    if (autoDismiss) {
      autoDismiss();
    }
  }, [autoDismiss]);

  return (
    <dialog
      className={clsx(
        'z-100 fixed top-0 left-0 flex w-full items-center justify-between p-4 text-white sm:py-6 sm:px-8',
        theme === 'error' && 'bg-lush-red',
        theme === 'warning' && 'bg-lush-amber',
        theme === 'info' && 'bg-lush-dark-gray',
        theme === 'success' && 'bg-lush-green',
        !!autoDismiss && autoDismissStyles
      )}
      data-testid="alert-dialog"
    >
      <p className="ml-2 flex items-center text-sm leading-5 sm:text-base sm:leading-6">{t(message)}</p>

      {dismissable ? (
        <button
          type="button"
          className="hover:opacity-60 hover:transition-opacity hover:duration-300"
          onClick={dismissable}
        >
          <Icon icon="close" size="1.5em" />
        </button>
      ) : null}
    </dialog>
  );
};

export default Alert;
