export const ctaStyle = { boxShadow: '0px 0px 0px 1px #fff' };

export const inputProps = {
  style: {
    border: '0',
    color: '#fff',
    backgroundColor: 'rgba(255, 255, 255, 0.25)',
    paddingLeft: '50px'
  }
};

export const rowProps = { style: { position: 'relative' } };

export const iconStyle = {
  position: 'absolute',
  left: '10px',
  top: '50%',
  transform: 'translateY(-50%)'
};
